import { useEffect, useRef, useState } from "react";
import { MediaType } from "../../../Types/MediaType";
import axios from "axios";
import Swal from "sweetalert2";
import { NewsArticleType } from "../../../Types/NewsArticleType";

const NewsMediaManagement = ({ news }: { news?: NewsArticleType }) => {
  const [medias, setMedias] = useState<Array<MediaType> | undefined>(undefined);
  const mediasRef = useRef<(HTMLImageElement | null)[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<HTMLImageElement | null>(
    null,
  );
  const closeRef = useRef<HTMLButtonElement>(null);
  const getMedias = () => {
    axios
      .get("medias")
      .then((response) => {
        if (response.status === 200) {
          setMedias(response.data);
        }
      })
      .catch((error) => console.error(error));
  };
  const setImageArticle = () => {
    if (!selectedMedia && !news) {
      return;
    }
    if (selectedMedia?.dataset.imageid && news?.id) {
      // let tmpArticle = {
      //   ...news,
      //   image_url: parseInt(selectedMedia?.dataset.imageid),
      // };
      axios
        .put("/news/" + news.id, {
          image_url: parseInt(selectedMedia?.dataset.imageid),
        })
        .then(() => {
          if (closeRef.current) {
            Swal.fire("image changée", "", "success");
            //TODO close modal
          }
        });
    }
  };
  const selectMedia = (index: number) => {
    for (let media of mediasRef.current) {
      media?.classList.remove("active");
    }
    mediasRef.current[index]?.classList.add("active");
    setSelectedMedia(mediasRef.current[index]);
  };
  useEffect(() => {
    if (medias === undefined) {
      getMedias();
    }
  }, [medias]);
  return (
    <>
      <div
        className="modal fade"
        id="mediaModal"
        tabIndex={-1}
        aria-labelledby="mediaModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="mediaModalLabel">
                Medias
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {medias &&
                  medias.map((media, index) => {
                    let mediaSrc = media.url;
                    if (media.extension === "pdf") {
                      if (media.thumb != null) {
                        mediaSrc = media.thumb;
                      }
                    }
                    return (
                      <div
                        className="col-lg-2 col-md-3 col-sm-6 p-2"
                        key={index}
                      >
                        <img
                          className="img-fluid img-thumbnail medialist"
                          src={axios.getUri() + mediaSrc}
                          alt="thumb"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                          onClick={() => selectMedia(index)}
                          ref={(element) =>
                            (mediasRef.current[index] = element)
                          }
                          data-imageId={media.id}
                        />
                        <p>{media.name}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="modal-footer">
              {selectedMedia && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={setImageArticle}
                >
                  Enregistrer en image de catégorie
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setSelectedMedia(null)}
                ref={closeRef}
                id={"closeBtn"}
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#mediaModal"
      >
        Gestion des Medias
      </button>
    </>
  );
};

export default NewsMediaManagement;
