import { Navigate } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import useAuth from "../Hooks/useAuth";
import Forbidden from "../Features/Authentication/Page/Forbidden";
// import { useUser } from "../Hooks/useUser";
import { User } from "../Types/UserType";

export enum roleEnum {
  ROLE_USER,
  ROLE_MANAGER,
  ROLE_REDACTOR,
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
}

const PrivateRoute = ({
  children,
  role = roleEnum.ROLE_USER,
}: {
  children: ReactElement<any>;
  role?: roleEnum;
}) => {
  const { isLoggedIn, getUser } = useAuth();
  const [user, setUser] = useState<User | null>(null);
  // const { user } = useUser();

  useEffect(() => {
    if (!user) {
      setUser(getUser());
    }
  }, [user]);
  const render = () => {
    if (user && !user.roles.includes(roleEnum[role])) {
      return <Forbidden />;
    }
    return <>{children}</>;
  };

  return isLoggedIn() ? (
    <>{render()}</>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default PrivateRoute;
