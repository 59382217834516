const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> 0.0.2
      </div>
      <strong>
        Copyright &copy; 2024 <a href="https://ls-concept.events">LS CONCEPT</a>
        .
      </strong>{" "}
      All rights reserved.
    </footer>
  );
};

export default Footer;
