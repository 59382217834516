import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { DropzoneAdmin } from "./Dropzone/Dropzone";
import PILoader from "../../Loader/Loader";
import { MediaType } from "../../../Types/MediaType";
import Swal from "sweetalert2";
import { NewsArticleType } from "../../../Types/NewsArticleType";
import { BrandType } from "../../../Types/BrandType";
import NewsMediaManagement from "../Components/NewsMediaManagement";

interface FormValues {
  id?: number;
  brand?: Array<BrandType> | string;
  content?: string;
  createdAt?: Date;
  updatedAt?: Date;
  media?: Array<MediaType>;
  title?: string;
}
const FormAddEditNews = ({
  news,
  setNews,
}: {
  news: NewsArticleType | null;
  setNews: Dispatch<SetStateAction<any>>;
}) => {
  const [formValues, setFormValues] = useState<FormValues | null>({
    brand: "-1",
  });
  const [siteList, setSiteList] = useState<Array<any> | null>(null);
  const [defaultContent, setDefaultContent] = useState<string | undefined>(
    undefined,
  );
  const [articleLoaded, setArticleLoaded] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [media, setMedias] = useState<Array<MediaType> | null | undefined>(
    undefined,
  );
  const [isMediaFetching, setIsMediaFetching] = useState<boolean>(false);

  const [brands, setBrands] = useState<Array<BrandType> | null>(null);

  const getSiteList = () => {
    axios
      .get("/sites")
      .then((response) => {
        if (response.status === 200) {
          setSiteList(response.data);
          setArticleLoaded(true);
        }
      })
      .catch((error) => console.error(error));
  };
  const getMedias = async () => {
    const mediasArray: Array<MediaType> = [];
    if (news?.medias && !media && !isMediaFetching) {
      setIsMediaFetching(true);
      for (const mediaFromArticle of news.medias) {
        const response = await axios.get("media/" + mediaFromArticle.id);
        if (response.status === 200) {
          mediasArray.push(response.data);
        }
      }
      setMedias(mediasArray);
      setIsMediaFetching(false);
    }
  };

  const getBrands = () =>
    axios.get("/brands").then((response) => {
      if (response.status === 200) {
        setBrands(response.data);
      }
    });
  const handleFiles = (files: Array<File>) => {
    const formData = new FormData();
    files.forEach((image, index) => {
      formData.append(`media-${index}`, image, image.name);
    });

    uploadMedia(formData);
  };
  const uploadMedia = (files: FormData) => {
    setIsUploading(true);
    axios
      .post("media", files)
      .then((r) => {
        if (r.status === 200) {
          setFormValues({ ...formValues, media: r.data });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsUploading(false);
      });
  };

  const removeMedia = (id: string | number) => {
    Swal.fire({
      title: "Voulez-vous supprimer l'image ?",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("article/" + news?.id + "/media/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(
                "Supprimé ! (rechargez pour voir la suppression)",
                "",
                "success",
              );
              if (news && news.medias) {
                news.medias.filter((media, key) => {
                  if (media.id === id && news?.medias?.[key]) {
                    delete news.medias[key];
                    getMedias();

                    //TODO CHECK DELETE
                  }
                });
              }
            }
          });
      }
    });
  };

  useEffect(() => {
    if (articleLoaded) {
      if (siteList) {
        siteList.reduce((site) => {
          if (site.id === 1) {
            return site;
          }
        });
      }
    }
  }, [articleLoaded]);

  useEffect(() => {
    if (!siteList) {
      getSiteList();
    }
  }, [siteList]);

  useEffect(() => {
    setNews(formValues);
    getMedias();
  }, [formValues]);

  useEffect(() => {
    if (news) {
      setFormValues({ ...news });
      setDefaultContent(news.content);
    }
  }, [news]);

  useEffect(() => {
    if (!brands) {
      getBrands();
    }
  }, [brands]);

  const handleEditorInit = (editor: any) => {
    // Configurer TinyMCE pour appliquer une classe par défaut aux images
    editor.on("ObjectResized", (e: any) => {
      if (e.target.nodeName === "IMG") {
        e.target.classList.add("img-fluid");
      }
    });
    editor.on("BeforeSetContent", (e: any) => {
      e.content = e.content.replace(/<img /g, '<img class="img-fluid" ');
    });
  };

  return (
    <>
      {isUploading && <PILoader />}
      <form>
        {brands && (
          <div className="form-group">
            <label>Entité</label>
            <select
              className="form-control"
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  brand: event.target.value,
                });
              }}
              defaultValue={
                formValues?.brand && formValues?.brand.length === 1
                  ? Array.isArray(formValues?.brand)
                    ? formValues?.brand[0].id
                    : "-1"
                  : "-1"
              }
            >
              <option defaultChecked value="-1">
                Tout le monde
              </option>
              {brands.map((brand, key) => {
                return (
                  <option key={key} value={brand.id}>
                    {brand.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="form-group">
          <label>Titre</label>
          <input
            type="text"
            defaultValue={formValues?.title}
            className="form-control"
            placeholder="Nouveauté...."
            onChange={(event) =>
              setFormValues({ ...formValues, title: event.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label>Contenu</label>
          <Editor
            apiKey="88fww1o4ojw3a3bylrsvoj7oomtg101yv2q7wfu7r7zv1p07"
            init={{
              language: "fr_FR",
              language_url: "/Intl/langs/fr_FR.js",
              height: "600",
              setup: handleEditorInit,
            }}
            initialValue={defaultContent}
            plugins={["accordion", "media", "code", "table", "image"]}
            onEditorChange={(val) =>
              setFormValues({ ...formValues, content: val })
            }
          />
          {news && (
            <div className="row my-4 text-center">
              <div className="col">
                <NewsMediaManagement news={news} />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col d-flex">
              {media?.map((mediaObject, index) => {
                let url = axios.getUri() + mediaObject.url;
                if (mediaObject.extension === "pdf") {
                  url = axios.getUri() + mediaObject.thumb;
                }
                return (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <i
                        className="fa fa-trash"
                        style={{ position: "absolute", right: "0" }}
                        onClick={() => removeMedia(mediaObject.id)}
                      ></i>
                      <img
                        src={url}
                        alt={mediaObject.name}
                        className="img-fluid img-thumbnail m-2"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                        key={index}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <DropzoneAdmin callback={handleFiles} />
        </div>
      </form>
    </>
  );
};
export default FormAddEditNews;
