import Swal from "sweetalert2";
import CocartApi from "../../../../Hooks/WooCommerce/CocartApi";

const addToCartHelper = ({
  productId,
  qty = 1,
  variation,
}: {
  productId: number;
  qty: number;
  variation?: object;
}) => {
  // const WC = CocartApi();
  const CartKey = localStorage.getItem("cart_key");

  let CartObject = {
    id: productId.toString(),
    quantity: qty.toString(),
  };
  let endpoint = "cart/add-item";

  if (CartKey) {
    CartObject = Object.assign(CartObject, {
      cart_key: CartKey,
    });
    endpoint += "?cart_key=" + CartKey;
  }
  if (variation) {
    CartObject = Object.assign(CartObject, {
      variation: variation,
    });
  }
  return CocartApi()
    .post(endpoint, CartObject)
    .then((response) => {
      if (response.status === 200) {
        if (!CartKey && response.data.cart_key) {
          localStorage.setItem("cart_key", response.data.cart_key);
          localStorage.setItem("cart_hash", response.data.cart_hash);
        }
        Swal.fire("Ajouté au panier", "", "success");
        return response.data;
      } else if (response.status === 400) {
        Swal.fire("Erreur", "", "error");
      }
    })
    .catch((error) => {
      Swal.fire("Erreur", "", "error");
      console.error("Response Status:", error.response);
      return error.data;
    });
};

export default addToCartHelper;
