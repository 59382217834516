import Breadcrumb from "../../../Template/Breadcrumb";
import { Outlet } from "react-router-dom";
import ShopBasketLink from "../Components/ShopBasketLink";
import { createContext } from "react";
import useCart from "../../../Hooks/WooCommerce/useCart";
export const CartContext = createContext<any>({}); //TODO REFACTO TYPES
const ShopBase = () => {
  const { productsCart, getCartProducts } = useCart();
  return (
    <CartContext.Provider value={{ productsCart, getCartProducts }}>
      <div className="content-wrapper shop-wrapper">
        <Breadcrumb title={`Shop`}>
          <div className="float-right">
            <ShopBasketLink />
          </div>
        </Breadcrumb>
        <div className="content h-100">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CartContext.Provider>
  );
};

export default ShopBase;
