import { NavLink } from "react-router-dom";
import ProductImageNotFound from "./ProductImageNotFound";

const CategoryCard = ({
  category,
  size,
}: {
  category: {
    count: number;
    id?: number;
    name?: string;
    image?: { src: string };
    slug?: string;
  };
  index?: number;
  size?: number;
}) => {
  const getSize = () => {
    let returnSize;
    if (size) {
      switch (size) {
        case 1:
          returnSize = "col-lg-2 col-md-4 col-sm-12";
          break;
        case 2:
          returnSize = "col-lg-4 col-md-4 col-sm-12";
          break;
        case 3:
          returnSize = "col-lg-6 col-md-6 col-sm-12";
          break;
        case 4:
          returnSize = "col-lg-12 col-md-12 col-sm-12";
          break;
        default:
          returnSize = "col-lg-4 col-md-6 col-sm-12";
      }
    } else returnSize = "col-lg-4 col-md-6 col-sm-12";
    return returnSize;
  };
  return (
    <>
      {category.count > 0 && (
        <div className={`${getSize()}`}>
          <div className="card">
            <div className="row">
              <div className="col-4 d-flex">
                {category?.image?.src ? (
                  <img
                    src={category.image.src}
                    alt="category image"
                    className="img-fluid"
                    style={{
                      objectFit: "cover",
                      aspectRatio: "50/50",
                    }}
                  />
                ) : (
                  <ProductImageNotFound />
                )}
              </div>
              <div className="col-8 d-flex justify-content-around flex-column">
                <div className="row">
                  <div className="col">
                    <h3 className="font-vitesse-800">{category.name}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <NavLink to={"/shop/category/" + category.slug}>
                      Voir les produits
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryCard;
