import useAuth from "../../../Hooks/useAuth";
import { useEffect, useState } from "react";
import { User } from "../../../Types/UserType";
import { ElearningArticleType } from "../../../Types/ElearningArticleType";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../../../Template/Breadcrumb";
import PILoader, { LoaderType } from "../../Loader/Loader";

function ELearning() {
  const [user, setUser] = useState<User | null>(null);
  const [articles, setArticles] = useState<Array<ElearningArticleType> | null>(
    null,
  );
  const { getUser } = useAuth();

  const getArticles = () => {
    axios.get("articles/public").then((response) => {
      if (response.status === 200) {
        setArticles(response.data);
      }
      if (response.status === 404) {
        console.error("Article not found");
      }
    });
  };

  useEffect(() => {
    if (!articles) {
      getArticles();
    }
  }, [articles]);

  useEffect(() => {
    if (!user) {
      setUser(getUser());
    }
  }, [user]);

  return (
    <div className="content-wrapper">
      <Breadcrumb title={"E-Learning"} />
      {!articles && (
        <PILoader type={LoaderType.Classic} className={"mx-3 p-2"} />
      )}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {articles &&
                  articles.map((article, key) => {
                    return (
                      <div className="col-lg-6 col-md-6 col-sm-6" key={key}>
                        <div
                          className="card-overlay"
                          style={{
                            background:
                              "url(" +
                              axios.getUri() +
                              article.image_url?.url +
                              ")  ",
                          }}
                        >
                          <div className="card elearning-card p-0">
                            <div className="card-body p-3">
                              <h2 className="card-title pt-2 pb-3">
                                {article.name}
                              </h2>
                              {/*<iframe width="auto" className="youtube-embed"*/}
                              {/*        src="https://www.youtube.com/embed/gvu87sjt6Wo?si=yHayoBe1e7AHTEgW"*/}
                              {/*        title="YouTube video player" frameBorder="0"*/}
                              {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                              {/*        allowFullScreen></iframe>*/}
                            </div>
                            <div className="card-footer">
                              <NavLink
                                to={article?.url}
                                className={"card-link"}
                              >
                                Documentation
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ELearning;
