import { useEffect, useState } from "react";
import axios from "axios";
import PILoader, { LoaderType } from "../../Loader/Loader";
import { Site } from "../../../Types/SiteType";
import AddEditSiteModal from "./AddEditSiteModal";

const SiteList = () => {
  const [site, setSite] = useState<Array<Site> | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const getSites = () => {
    setIsloading(true);
    axios
      .get("sites")
      .then((response) => {
        if (response.status === 200) {
          setSite(response.data);
          setIsloading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (site === undefined) {
      getSites();
    }
  }, [site]);

  return (
    <>
      {isLoading && <PILoader type={LoaderType.Logo_Black} size={"200px"} />}
      {site && (
        <table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom du site</th>
              <th>Ville</th>
              <th>Adresse</th>
              <th>Cp</th>
              <th>Responsable</th>
              <th>Tél</th>
              <th>Brand</th>
            </tr>
          </thead>
          <tbody>
            {site &&
              site.map((site, index) => {
                return (
                  <tr key={index}>
                    <td>{site.id}</td>
                    <td>{site.name}</td>
                    <td>{site.city}</td>
                    <td>{site.address}</td>
                    <td>{site.cp}</td>
                    <td>
                      {site.last_name} {site.first_name}
                    </td>

                    <td>{site.phone}</td>
                    <td>{site.brand.name}</td>
                    <td>
                      <AddEditSiteModal
                        site={site}
                        key={index}
                        dataId={index}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default SiteList;
