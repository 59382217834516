import slugify from "slugify";
import WooCommerceAPI from "../../../../Hooks/WooCommerce/WooCommerceApi";

const getProductVariationWithParentIdAndAttributes = async ({
  productId,
  attributesList,
}: {
  productId: number;
  attributesList: Array<any>;
}) => {
  const WC = WooCommerceAPI();
  const getProductVarations = async () => {
    return WC.get(`products/${productId}/variations`, { per_page: 100 })
      .then((response: any) => {
        return response.data;
      })
      .catch((e: any) => {
        throw new Error(e);
      });
  };

  // const normalizeOption = (slug: string, option: string): string => {
  //   const regex = /^pa_/;
  //   let normalizedOption = option;
  //   if (regex.test(slug)) {
  //     normalizedOption = slugify(normalizedOption, { lower: true });
  //   }
  //   return normalizedOption;
  // };

  const variations = await getProductVarations();
  if (variations) {
    const matchedVariation = variations.find((variation: any) => {
      return Object.entries(attributesList).every(([key, value]) => {
        return variation.attributes.some((attribute: any) => {
          return (
            attribute.slug === key.replace("attribute_", "") &&
            slugify(attribute.option, { lower: true }) === value
          );
        });
      });
    });
    if (matchedVariation) {
      return matchedVariation;
    }
  } else throw new Error("No variations");

  // console.debug(attributes);
  // console.debug(variations);
};

export default getProductVariationWithParentIdAndAttributes;
