import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer: Object = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
const thumb: Object = {
  display: "inline-flex",
  justifyContent: "center",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner: Object = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img: Object = {
  display: "block",
  width: "auto",
  height: "100%",
};

export function DropzoneAdmin({ callback }: { callback: any }) {
  const [files, setFiles] = useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    maxSize: 8388608,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(() => {
    if (files.length > 0) {
      callback(files);
    }
  }, [files]);

  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {file.type.startsWith("image") && (
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
            alt="image preview"
          />
        )}
        {file.type.startsWith("application") && (
          <i className="fa fa-file-pdf m-auto fa-3x"></i>
        )}
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container mt-5">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop les fichiers ici (pdf, jpg)</p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}
