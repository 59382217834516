import Breadcrumb from "../../../Template/Breadcrumb";
import PILoader, { LoaderType } from "../../Loader/Loader";
import useArticle from "../../../Hooks/useArticle";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../Hooks/useUser";

const ElearningSingle = () => {
  const params = useParams();
  const { article, medias } = useArticle({ name: params.article_url });
  const { user } = useUser();

  return (
    <div className="content-wrapper">
      {article?.name && <Breadcrumb title={`E-Learning / ${article?.name}`} />}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {article ? (
                  <div className="col-lg-12">
                    <div className="card">
                      {article.content && (
                        <div
                          className="card-body"
                          dangerouslySetInnerHTML={{ __html: article.content }}
                        ></div>
                      )}
                      {medias &&
                        medias.map((mediaObject, index) => {
                          if (mediaObject.extension === "pdf") {
                            return (
                              <div key={index}>
                                <h2 className="text-center mb-5">
                                  Documentation
                                </h2>
                                <iframe
                                  src={axios.getUri() + mediaObject.url}
                                  frameBorder={0}
                                  height={"800px"}
                                  width={"100%"}
                                ></iframe>
                              </div>
                            );
                          }
                        })}
                      {user && user.roles.includes("ROLE_ADMIN") && (
                        <div className="col-6 offset-3 text-center p-3">
                          <NavLink
                            to={"/admin/elearning/article/edit/" + article.url}
                            className="btn btn-primary"
                          >
                            Edit
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <PILoader type={LoaderType.Classic} className={"m-3 p-2"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElearningSingle;
