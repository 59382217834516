import { NavLink } from "react-router-dom";
import { ElearningArticleType } from "../../../Types/ElearningArticleType";
import { roleEnum } from "../../../Router/PrivateRoute";
import AdminGuard from "../AdminGuard";
import Breadcrumb from "../../../Template/Breadcrumb";

const AdminElearningComponent = ({
  articlesElearning,
  role = roleEnum.ROLE_USER,
}: {
  articlesElearning: Array<ElearningArticleType> | null;
  role?: roleEnum;
}) => {
  return (
    <AdminGuard role={role}>
      <div className="row">
        <div className="col">
          <Breadcrumb title={"Articles E-Learning"} />
        </div>
        <div className="col content-header justify-content-end d-flex align-items-start mx-2">
          <NavLink
            to={"/admin/elearning/article/add"}
            className={"btn btn-primary"}
          >
            <i className="fa fa-plus"></i> Ajouter un article
          </NavLink>
        </div>
      </div>
      <div className="row">
        {articlesElearning &&
          articlesElearning.map((article, key) => {
            return (
              <div className="col-3" key={key}>
                <div className="small-box bg-primary">
                  <div className="inner">
                    <h4>{article.name}</h4>
                    {/*<p>New Orders</p>*/}
                  </div>
                  <div className="icon">
                    <i className="fas fa-file"></i>
                  </div>
                  <NavLink
                    to={`elearning/article/edit/${article.url}`}
                    className="small-box-footer"
                  >
                    Editer <i className="fas fa-arrow-circle-right"></i>
                  </NavLink>
                </div>
              </div>
            );
          })}
      </div>
    </AdminGuard>
  );
};

export default AdminElearningComponent;
