import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../Page/ShopBase";

const ShopBasketLink = () => {
  const Cart = useContext(CartContext);
  const { productsCart } = Cart;
  const [itemsCount, setItemsCount] = useState<number | null>(null);

  useEffect(() => {
    if (productsCart?.item_count > 0) {
      setItemsCount(productsCart?.item_count);
    }
  }, [productsCart]);
  return (
    <NavLink to="/cart">
      <i className="fa fa-shopping-bag fa-2x" style={{ color: "#000000" }}></i>
      {itemsCount && itemsCount !== 0 && (
        <span className="badge badge-danger navbar-badge">{itemsCount}</span>
      )}
    </NavLink>
  );
};
export default ShopBasketLink;
