const ProductImageNotFound = () => {
  return (
    <img
      src="/img/error/404.jpg"
      alt="404"
      className="img-fluid"
      style={{
        objectFit: "cover",
        aspectRatio: "50/50",
      }}
    />
  );
};

export default ProductImageNotFound;
