import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { User } from "../Types/UserType";
import useAuth from "../Hooks/useAuth";
import { ElearningArticleType } from "../Types/ElearningArticleType";
import axios from "axios";
import AdminGuard from "../Features/Admin/AdminGuard";
import { roleEnum } from "../Router/PrivateRoute";
import AsideBrandLogo from "./AsideBrandLogo";
import PILoader, { LoaderType } from "../Features/Loader/Loader";

const Aside = () => {
  const [user, setUser] = useState<User | null>(null);
  const [articlesElearning, setArticlesElearning] =
    useState<Array<ElearningArticleType> | null>(null);

  const getArticlesElearning = () =>
    axios
      .get("/articles")
      .then((response) => {
        if (response.status === 200) {
          setArticlesElearning(response.data);
        }
      })
      .catch((error) => console.error(error));

  useEffect(() => {
    if (!articlesElearning) {
      getArticlesElearning();
    }
  }, [articlesElearning]);

  const { getUser: userLocal } = useAuth();
  useEffect(() => {
    if (!user) {
      setUser(userLocal());
    }
  }, [userLocal]);
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {!user && <PILoader type={LoaderType.Classic} />}
      {/*<span className="brand-text font-weight-light">Prison Island Media</span>*/}
      <AsideBrandLogo user={user} />
      <div className="sidebar">
        <div className="user-panel mt-3 mb-3 d-flex text-center text-uppercase">
          <div className="info">
            <a href="#" className="d-block">
              {user && user.site?.name}
              {user && user?.roles.includes("ROLE_REDACTOR") && user.username}
            </a>
          </div>
        </div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink to={"news"} className={"nav-link"}>
                <i className="nav-icon fas fa-newspaper"></i>
                <p>News</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={"e-learning"} className={"nav-link"}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  E-Learning
                  <i className="right fas fa-angle-left"></i>
                </p>
              </NavLink>
              <ul className="nav nav-treeview">
                {articlesElearning !== null &&
                  articlesElearning?.map((article, key) => {
                    return (
                      <li className="nav-item" key={key}>
                        <NavLink
                          replace={true}
                          to={`/e-learning/${article.url}`}
                          className="nav-link"
                        >
                          <i className="far fa-circle nav-icon"></i>
                          <p>{article.name}</p>
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            </li>
            <li className="nav-item">
              <NavLink to={"shop"} className={"nav-link"}>
                <i className="nav-icon fas fa-shopping-bag"></i>
                <p>Shop</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={"contact"} className={"nav-link"}>
                <i className="nav-icon fas fa-th"></i>
                <p>Contact</p>
              </NavLink>
            </li>
            <AdminGuard role={roleEnum.ROLE_REDACTOR}>
              <li className="nav-item">
                <NavLink to={"admin"} className={"nav-link"}>
                  <i className="nav-icon fas fa-cog"></i>
                  <p>Admin</p>
                </NavLink>
              </li>
            </AdminGuard>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Aside;
