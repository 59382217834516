import React, { useContext, useEffect, useRef, useState } from "react";
import { Product } from "../Type/Product";
import { useParams } from "react-router-dom";
import PILoader from "../../Loader/Loader";
import Loader, { LoaderType } from "../../Loader/Loader";
import WooCommerceApi from "../../../Hooks/WooCommerce/WooCommerceApi";
import slugify from "slugify";
import useCart from "../../../Hooks/WooCommerce/useCart";
import { CartContext } from "./ShopBase";
import getProductVariationWithParentIdAndAttributes from "../Helpers/ShopHelper/getProductVariationWithParentIdAndAttributes";
import getPrice from "../Helpers/ShopHelper/getPrice";

type formAddToCart = {
  quantity: number;
  selectedOptions?: Object;
};
const ShopProductSingle = () => {
  const { addToCart } = useCart();
  const params = useParams();
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const Cart = useContext(CartContext);
  const { getCartProducts } = Cart;

  const getProduct = () => {
    setIsloading(true);
    WooCommerceApi()
      .get("products/" + params.product_id)
      .then((response: any) => {
        if (response.status === 200) {
          setProduct(response.data);
          const defaultOptions: Array<any> = [];
          response.data.attributes.map((r: any) => {
            const defaultSelected = normalizeOption(r.slug, r.options[0]);
            defaultOptions.push({ ["attribute_" + r.slug]: defaultSelected });
          });
          const combindedOptions = defaultOptions.reduce((acc, current) => {
            return { ...acc, ...current };
          }, {});
          setSelectedOptions(combindedOptions);

          setIsloading(false);
        }
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    let formObject: formAddToCart = { quantity: -1, selectedOptions: {} };
    for (let data of formData.entries()) {
      const [key, value] = data;
      if (key === "quantity") {
        Object.assign(formObject, { [key]: value });
      } else
        Object.assign(formObject, {
          selectedOptions: { ...formObject.selectedOptions, [key]: value },
        });
    }
    processAddToCart(formObject);
  };
  const processAddToCart = (form: formAddToCart) => {
    if (form.quantity && product?.id) {
      addToCart({
        productId: product.id,
        qty: form.quantity,
        variation: form.selectedOptions ?? undefined,
      }).then(() => {
        getCartProducts();
      });
    }
  };
  const normalizeOption = (slug: string, option: string): string => {
    const regex = /^pa_/;
    let normalizedOption = option;
    if (regex.test(slug)) {
      normalizedOption = slugify(normalizedOption, { lower: true });
    }
    return normalizedOption;
  };

  useEffect(() => {
    if (selectedOptions) {
      if (product?.id) {
        setIsUpdating(true);
        getProductVariationWithParentIdAndAttributes({
          productId: product.id,
          attributesList: selectedOptions,
        })
          .then((data) => {
            if (data) {
              setProduct({
                ...product,
                images: [{ ...product.images[0], src: data.image.src }],
                price: data.regular_price,
              });
            }
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!product) {
      getProduct();
    }
  }, [product]);

  return (
    <>
      {product && !isLoading && (
        <div className="row flex-column flex-wrap justify-content-center shop-product-single">
          <section style={{ overflow: "auto" }}>
            <div className="row m-0">
              <div className="col-lg-6 col-md-12 col-sm-12 text-center">
                {isUpdating ? (
                  <Loader type={LoaderType.Classic} size={"10px"} />
                ) : (
                  <img
                    src={product.images[0]?.src}
                    className="img-fluid m-4 m-sm-2 m-md-4"
                    alt=""
                    style={{ maxHeight: "50vh" }}
                  />
                )}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 px-5 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col">
                    <h1 className="font-vitesse-800">{product.name}</h1>
                    <hr />
                    <p
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    ></p>
                  </div>
                </div>
                <div className="row">
                  <form onSubmit={(e) => handleSubmit(e)} ref={formRef}>
                    <div className="col">
                      {product.attributes &&
                        Object.entries(product.attributes).map(
                          (attribute: any, index) => {
                            return (
                              <div
                                className="d-flex align-items-center"
                                key={index}
                              >
                                <div className="col-6">
                                  <label className="mb-0 ml-2 mr-2">
                                    {attribute[1].name}
                                  </label>
                                </div>
                                <div className="col-6">
                                  <select
                                    className="custom-select form-control-border"
                                    id={attribute[1].name}
                                    name={
                                      "attribute_" +
                                      slugify(attribute[1].slug, {
                                        lower: true,
                                      })
                                    }
                                    onChange={(e) => {
                                      setSelectedOptions({
                                        ...selectedOptions,
                                        ["attribute_" + attribute[1].slug]:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    {Object.values(attribute[1].options).map(
                                      (option: any, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={normalizeOption(
                                              attribute[1].slug,
                                              option,
                                            )}
                                          >
                                            {option}
                                          </option>
                                        );
                                      },
                                    )}
                                  </select>
                                </div>
                              </div>
                            );
                          },
                        )}
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div className="row justify-content-between">
                            <div className="col-md-4 my-2">
                              <div className="d-flex align-items-center">
                                <label htmlFor="qty" className="mb-0 ml-2 mr-2">
                                  Qté
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={"qty"}
                                  name="quantity"
                                  defaultValue={1}
                                  style={{ borderRadius: ".6em" }}
                                />
                              </div>
                            </div>
                            {isUpdating ? (
                              <Loader type={LoaderType.Classic} size={"10px"} />
                            ) : (
                              <>
                                {product.price && (
                                  <div className="col-md-4 d-flex align-items-center flex-column align-content-end">
                                    <small>PU/HT</small>
                                    <h2>
                                      {getPrice({
                                        productPrice: parseFloat(product.price),
                                        suffix: "€",
                                        fromCents: false,
                                      })}
                                    </h2>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 my-2">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 p-2"
                            style={{
                              borderRadius: ".6em",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            Ajouter au panier
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {isLoading && <PILoader type={LoaderType.Logo_Black} size={"200px"} />}
    </>
  );
};

export default ShopProductSingle;
