import PILoader, { LoaderType } from "../../Loader/Loader";

export const Forbidden = () => {
  return (
    <div className="content-wrapper">
      <div className="content pi-contact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-center align-content-center p-5">
                <div className="p-5">
                  <PILoader type={LoaderType.Logo_Black} size="200px" />
                </div>
                <p>Accès interdit</p>
                <p>vous n'avez pas les autorisations necéssaires</p>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
