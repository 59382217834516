import useCategories from "../../../Hooks/WooCommerce/useCategories";
import { useParams } from "react-router-dom";
import { LoaderType } from "../../Loader/Loader";
import { useEffect, useState } from "react";
import CategoryCard from "../Components/CategoryCard";
import ShopProductsList from "./ShopProductsList";
import PILoader from "../../Loader/Loader";

const ShopCategory = () => {
  const params = useParams();
  const { categories } = useCategories();
  const [category, setCategory] = useState<{ id: string; name: string } | null>(
    null,
  );
  const [subCategories, setSubCategories] = useState<Array<object> | []>([]);
  const getCurrentCategory = () => {
    if (!categories) {
      return;
    }
    const currentCategory = categories.filter(
      (category) => category.slug === params.category,
    );
    if (currentCategory[0]) {
      setCategory(currentCategory[0]);
    }
  };
  const getSubCategories = () => {
    if (!category || !categories) {
      return;
    }
    const subCategoriesObjects: Array<object> = categories.filter(
      (categories) => categories.parent === category?.id,
    );
    setSubCategories(subCategoriesObjects);
  };
  useEffect(() => {
    getCurrentCategory();
  }, [categories, params]);

  useEffect(() => {
    if (category || categories) {
      getSubCategories();
    }
  }, [category]);
  useEffect(() => {
    console.debug(subCategories);
  }, [subCategories]);
  return (
    <>
      {categories && (
        <div className="row">
          {subCategories &&
            subCategories.map((category: any, index: any) => {
              return <CategoryCard category={category} key={index} size={2} />;
            })}
          <hr />
        </div>
      )}
      {category ? (
        <ShopProductsList categoryId={category && parseInt(category?.id)} />
      ) : (
        <PILoader type={LoaderType.Logo_Black} size="200px" />
      )}
    </>
  );
};

export default ShopCategory;
