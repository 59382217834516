import axios from "axios";
import { useLocalStorage } from "./useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useUser } from "./useUser";
import { User } from "../Types/UserType";
import { useState } from "react";
import SweetAlert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import setAuthToken from "../Helpers/setAuthToken";

const useAuthService = () => {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const navigate = useNavigate();
  const { user: userLocal, mutate } = useUser();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const Alert = withReactContent(SweetAlert);
  const login = (user: { password: string; username: string }) => {
    setIsLogin(true);

    const response = axios.post("/login_check", {
      username: user.username,
      password: user.password,
    });
    response
      .then((response) => {
        if (response?.status === 200) {
          const token = response.data?.token;
          setItem("token", token);
          setAuthToken(token);
          mutate();
          if (!userLocal) {
            navigate("/login");
          }
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Alert.fire({
            icon: "error",
            title: "Identifiants incorrects",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .finally(() => {
        setIsLogin(false);
      });
  };

  const logout = () => {
    removeItem("token");
    removeItem("user");
    // clear();
    navigate("/");
  };

  const register = () => {};

  const isLoggedIn = () => {
    return !!localStorage.getItem("token");
  };

  const user = (): User | null => {
    let localUser: string | null = getItem("user");
    let user: User | null = null;
    if (localUser) {
      user = JSON.parse(localUser);
    }
    return user;
  };

  return { login, logout, register, isLoggedIn, getUser: user, isLogin };
};

export default useAuthService;
