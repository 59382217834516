import useCart from "../../../Hooks/WooCommerce/useCart";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../Template/Breadcrumb";
import PILoader, { LoaderType } from "../../Loader/Loader";
import { NavLink } from "react-router-dom";
import getPrice from "../Helpers/ShopHelper/getPrice";

const ShopCart = () => {
  const { productsCart, clearCart, removeItem, makeOrder } = useCart();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [shippingPrice, setShippingPrice] = useState<string | undefined>(
    undefined,
  );

  const handleClearCart = () => {
    setIsLoading(true);
    clearCart();
  };

  const calcShipping = () => {
    if (!productsCart) {
      return;
    }
    if (productsCart.shipping?.packages) {
      if (productsCart.shipping.packages.default?.chosen_method) {
        const method_id = productsCart.shipping.packages.default.chosen_method;
        const method = productsCart.shipping.packages.default.rates[method_id];
        const label = method.label;
        if (label === "Frais de port sur devis") {
          setShippingPrice(label);
        }
      }
    } else setShippingPrice(undefined);
  };

  const GetProductLink = ({ item }: { item: any }): React.ReactNode => {
    if (item.meta.product_type == "variation") {
      return (
        <NavLink to={"/shop/product/" + item.meta.variation.parent_id}>
          {item.name}
        </NavLink>
      );
    }

    if (item.meta.product_type == "simple") {
      return <NavLink to={"/shop/product/" + item.id}>{item.name}</NavLink>;
    }
  };

  useEffect(() => {
    calcShipping();
    setIsLoading(true);
    if (productsCart) {
      setIsLoading(false);
    }
  }, [productsCart]);
  return (
    <div className="content-wrapper">
      <Breadcrumb title={`Panier`} />

      <div className="content h-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              {isLoading && (
                <div className="p-5 m-auto">
                  <PILoader type={LoaderType.Logo_Black} size={"200px"} />
                </div>
              )}
              {!productsCart ||
                (productsCart.item_count === 0 && !isLoading && (
                  <div className="p-5 m-5 text-center">
                    <h1 className="p-2">Oups ! Panier vide !</h1>
                    <PILoader type={LoaderType.Logo_Black} size={"200px"} />
                  </div>
                ))}
              {productsCart && productsCart?.item_count > 0 && !isLoading && (
                <>
                  <section>
                    <div className="row">
                      <table className="table-striped table cart-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Désignation</th>
                            <th>PU/HT</th>
                            <th>Quantité</th>
                            <th>Total HT</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {productsCart.items.map(
                            (item: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className={"p-0"}>
                                    <img
                                      src={item.featured_image}
                                      className="img-fluid"
                                      alt=""
                                      style={{ maxHeight: "70px" }}
                                    />
                                  </td>
                                  <td>
                                    <GetProductLink item={item} />
                                  </td>
                                  <td>
                                    {getPrice({
                                      productPrice: item.price,
                                      suffix: "€",
                                    })}
                                  </td>
                                  <td>{item.quantity.value}</td>
                                  <td>
                                    {getPrice({
                                      productPrice: item.totals.subtotal,
                                      suffix: "€",
                                    })}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        removeItem({ item_key: item.item_key })
                                      }
                                      className="btn btn-outline-secondary"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            },
                          )}
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section>
                    <div className="row">
                      <div className="col-lg-9 col-md-12 d-flex align-items-end">
                        <p>Franco de port: 150€ HT</p>
                      </div>
                      <div className="col-lg-3 col-md-12">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <th>Total HT</th>
                              <td>
                                {getPrice({
                                  productPrice: productsCart.totals.subtotal,
                                  suffix: "€",
                                })}
                              </td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <th>Nombre de produits</th>*/}
                            {/*  <td>{productsCart.item_count}</td>*/}
                            {/*</tr>*/}
                            <tr>
                              <th>TVA(20%)</th>
                              <td>
                                {getPrice({
                                  productPrice: productsCart.totals.total_tax,
                                  suffix: "€",
                                })}
                              </td>
                            </tr>

                            {/*{productsCart.shipping_total && (*/}
                            <tr>
                              <th>Livraison</th>
                              <td>
                                {!shippingPrice
                                  ? getPrice({
                                      productPrice:
                                        productsCart.totals.shipping_total,
                                      suffix: "€",
                                    })
                                  : shippingPrice}
                              </td>
                            </tr>
                            {/*)}*/}

                            <tr>
                              <th>Total TTC</th>
                              <td>
                                {getPrice({
                                  productPrice: productsCart.totals.total,
                                  suffix: "€",
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="row">
                      <div className="col">
                        <button
                          className="btn-outline-danger btn"
                          onClick={() => handleClearCart()}
                        >
                          Vider le panier
                        </button>
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-primary float-right"
                          onClick={() => makeOrder()}
                        >
                          Procéder à la commande
                        </button>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCart;
