import axios from "axios";
import useAuth from "./useAuth";

const useAuthService = (token?: string) => {
  const { logout } = useAuth();
  const token_local_storage = localStorage.getItem("token");
  const newToken = token || token_local_storage;

  if (newToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  axios.interceptors.response.use(null, function (error) {
    if (error.response.status === 401) {
      logout(); // TOdo correct disconnect (popup or sth)
    }
    return Promise.reject(error);
  });

  axios.interceptors.request.use(function (config) {
    const { headers = {} }: { headers: any } = config || {};
    if (headers["User-Agent"]) delete config.headers["User-Agent"];
    return config;
  });

  return null;
};

export default useAuthService;
