import { useEffect, useState } from "react";
import CocartApi from "./CocartApi";

const useCategories = () => {
  const [categories, setCategories] = useState<Array<any>>();

  const getCategories = () => {
    CocartApi()
      .get("products/categories", {
        params: {
          per_page: 100,
        },
      })
      .then((response) => {
        setCategories(response.data);
      });
  };

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
  }, [categories]);

  return { categories };
};

export default useCategories;
