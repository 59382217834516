import CocartApi from "./CocartApi";
import { useEffect, useState } from "react";
import WooCommerceApi from "./WooCommerceApi";
import { useUser } from "../useUser";
import { OrderRequest } from "../../Features/Shop/Type/Order";
import Swal from "sweetalert2";
import sendOrderEmail from "../../Helpers/WooCommerce/sendOrderEmail";
import addToCartHelper from "../../Features/Shop/Helpers/ShopHelper/addToCartHelper";
import getPrice from "../../Features/Shop/Helpers/ShopHelper/getPrice";

export type ProductsCartType = {
  item_count: number;
  items: any;
  totals: any;
  shipping_total: any;
  shipping: any;
};

const useCart = () => {
  const CC = CocartApi();
  const { user } = useUser();
  const WC = WooCommerceApi();
  const [productsCart, setProductsCart] = useState<
    ProductsCartType | undefined
  >(undefined);
  const cart_key_local = localStorage.getItem("cart_key");
  const getCartProducts = () => {
    const cart_key_local = localStorage.getItem("cart_key");
    if (!cart_key_local) {
      return null;
    }
    CC.get("cart", {
      params: {
        cart_key: cart_key_local,
      },
    }).then((response) => {
      if (response.status === 200) {
        setProductsCart(response.data);
      }
    });
  };

  const addToCart = ({
    productId,
    qty = 1,
    variation,
  }: {
    productId: number;
    qty: number;
    variation?: object;
  }) => {
    return addToCartHelper({ productId, qty, variation }).then(() => {
      getCartProducts();
    });
  };

  const clearCart = () => {
    let url = "cart/clear";
    if (cart_key_local) {
      url += "?cart_key=" + cart_key_local;
    }
    CC.post(url, {}).then((response) => {
      getCartProducts();
    });
  };

  const removeItem = ({ item_key }: { item_key: string }) => {
    if (!item_key && !cart_key_local) {
      return null;
    }
    CC.delete("cart/item/" + item_key + "?cart_key=" + cart_key_local, {}).then(
      (response) => {
        // CC.delete("cart/item/" + item_key, {}).then((response) => {
        getCartProducts();
      },
    );
  };

  const makeOrder = () => {
    if (
      !user?.email ||
      !user?.username ||
      !user?.site?.first_name ||
      !user?.site?.last_name ||
      !user?.site?.address ||
      !user?.site?.cp ||
      !user?.site?.city ||
      !user?.site?.phone
    ) {
      Swal.fire(
        "Erreur dans vos cordonnées, veuillez contacter LSConcept à l'adresse shop@ls-concept.events",
      );
      return;
    }
    let billing_info = {
      first_name: user.site.first_name,
      last_name: user.site.last_name,
      address_1: user.site.address,
      address_2: "",
      city: user.site.city,
      state: "",
      postcode: user.site.cp,
      country: "FR",
      email: user?.email,
      phone: user.site.phone,
    };
    let data: OrderRequest = {
      payment_method: "cod",
      payment_method_title: "Paiement différé",
      set_paid: false,
      billing: billing_info,
      shipping: billing_info,
      line_items: [],
      shipping_lines: [],
    };

    if (productsCart?.items) {
      productsCart.items.map((item: any) => {
        data.line_items.push({
          product_id: item.id,
          quantity: item.quantity.value,
        });
      });
      data.shipping_lines.push({
        method_id: productsCart.shipping.packages.default.chosen_method,
        method_title: "Standard",
        total: getPrice({
          productPrice: productsCart.totals.shipping_total,
          suffix: "",
        }),
      });
    }
    WC.post("orders", data)
      .then((response: any) => {
        if (response.status === 201) {
          Swal.fire("Commande passée !", "", "success");
          sendOrderEmail({ order: response.data });
          clearCart();
        }
      })
      .catch((error: any) => {
        console.error(error.response.data);
      });
  };

  useEffect(() => {
    if (productsCart === undefined) {
      getCartProducts();
    }
  }, [productsCart]);
  return {
    productsCart,
    clearCart,
    removeItem,
    makeOrder,
    addToCart,
    getCartProducts,
  };
};

export default useCart;
