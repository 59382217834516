import { Params } from "react-router-dom";
import React from "react";

const Breadcrumb = ({
  title,
  params,
  children,
}: {
  title: string;
  params?: Params<string> | undefined;
  children?: React.ReactNode;
}) => {
  return (
    <>
      {title && (
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 font-vitesse-900">{title}</h1>
              </div>
              {children && <div className="col-sm-6">{children}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Breadcrumb;
