import Breadcrumb from "../../../Template/Breadcrumb";
import PILoader, { LoaderType } from "../../Loader/Loader";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../Hooks/useUser";
import useNewsArticle from "../../../Hooks/useNewsArticle";
import moment from "moment";

const NewsSingle = () => {
  const params = useParams();
  const { article, medias } = useNewsArticle({ id: params.news_id });
  const { user } = useUser();

  return (
    <div className="content-wrapper">
      {article?.title && <Breadcrumb title={`News / ${article?.title}`} />}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                {article ? (
                  <div className="col-lg-12 offset-lg-6">
                    <div className="card">
                      {user && user.roles.includes("ROLE_REDACTOR") && (
                        <NavLink
                          to={"/admin/news/article/edit/" + article.id}
                          className="btn btn-primary position-absolute"
                          style={{ top: "-20px", right: "-20px" }}
                        >
                          <i className="fa fa-pencil-alt"></i>
                        </NavLink>
                      )}
                      {article.content && (
                        <div
                          className="card-body"
                          dangerouslySetInnerHTML={{ __html: article.content }}
                        ></div>
                      )}
                      {medias &&
                        medias.map((mediaObject, index) => {
                          if (mediaObject.extension === "pdf") {
                            return (
                              <div key={index}>
                                <h2 className="text-center mb-5">
                                  Documentation
                                </h2>
                                <iframe
                                  src={axios.getUri() + mediaObject.url}
                                  frameBorder={0}
                                  height={"800px"}
                                  width={"100%"}
                                ></iframe>
                              </div>
                            );
                          }
                        })}
                      <div className="d-flex flex-row-reverse p-2">
                        <span>
                          <i className="fa fa-calendar-times mr-2"></i>
                          {moment(article.createdAt).format("LLL")}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <PILoader type={LoaderType.Classic} className={"m-3 p-2"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsSingle;
