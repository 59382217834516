import { useEffect, useState } from "react";
import axios from "axios";
import { MediaType } from "../Types/MediaType";
import { NewsArticleType } from "../Types/NewsArticleType";

const useNewsArticle = ({ id, name }: { id?: string; name?: string }) => {
  const [article, setArticle] = useState<NewsArticleType | null>(null);
  const [medias, setMedias] = useState<Array<MediaType> | null>(null);
  const [isMediaFetching, setIsMediaFetching] = useState<boolean>(false);

  const getArticle = () => {
    let url;
    if (id) {
      url = `/news/${id}`;
    } else if (name) {
      url = `/news_url/${name}`;
    } else {
      console.error("This hook should be called with a name or an id");
      return;
    }

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setArticle(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getMedias = async () => {
    const mediasArray: Array<MediaType> = [];
    if (article?.medias && !medias && !isMediaFetching) {
      setIsMediaFetching(true);
      for (const mediaFromArticle of article.medias) {
        const response = await axios.get("media/" + mediaFromArticle.id);
        if (response.status === 200) {
          mediasArray.push(response.data);
        }
      }
      setMedias(mediasArray);
      setIsMediaFetching(false);
    }
  };

  useEffect(() => {
    if (!article) {
      getArticle();
    }
  }, []);

  useEffect(() => {
    if (article) {
      getMedias();
    }
  }, [article]);

  useEffect(() => {
    getArticle();
  }, [id, name]);

  return { article, medias };
};

export default useNewsArticle;
