//@ts-ignore
const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;

const WooCommerceAPI = () => {
  const WooCommerceProps: {
    PublicKey: string;
    PrivateKey: string;
    BaseUrl: string;
  } = {
    PublicKey: process.env.REACT_APP_WC_PUBLIC_KEY || "null",
    PrivateKey: process.env.REACT_APP_WC_PRIVATE_KEY || "null",
    BaseUrl: process.env.REACT_APP_WC_URL || "null",
  };

  const newInstance = () =>
    new WooCommerceRestApi({
      url: WooCommerceProps.BaseUrl,
      consumerKey: WooCommerceProps.PublicKey,
      consumerSecret: WooCommerceProps.PrivateKey,
      version: "wc/v3",
      axiosConfig: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      },
    });
  return newInstance();
};

export default WooCommerceAPI;
