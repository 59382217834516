import { useEffect, useState } from "react";
import axios from "axios";
import PILoader, { LoaderType } from "../../Loader/Loader";

const UserList = () => {
  const [users, setUsers] = useState<Array<any> | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const getUsers = () => {
    setIsloading(true);
    axios
      .get("user")
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data);
          setIsloading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (users === undefined) {
      getUsers();
    }
  }, [users]);

  return (
    <>
      {isLoading && <PILoader type={LoaderType.Logo_Black} size={"200px"} />}
      {users && (
        <table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Site</th>
              <th>Brand</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.roles.map((role: any, index: number) => role)}
                    </td>
                    <td>{user.site && user.site.name}</td>
                    <td>{user.site && user.site.brand.name}</td>
                    <td>
                      <button className="btn">
                        <i className="fa fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default UserList;
