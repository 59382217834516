import { User } from "../Types/UserType";
import { NavLink } from "react-router-dom";

const AsideBrandLogo = ({ user }: { user: User | null }) => {
  const logo = () => {
    switch (user?.site?.brand.id) {
      case 1:
        return {
          url: "/img/logo/pi_logo_white.png",
          alt: "PrisonIsland Logo",
          class: "brand-image-pi",
        };
      case 2:
        return {
          url: "/img/logo/alcatraz_white.png",
          alt: "Alcatraz Adventure Logo",
          class: "brand-image-alcatraz",
        };
      default:
        return {
          url: "/img/logo/ls_white.png",
          alt: "LS CONCEPT",
          class: "brand-image-ls",
        };
    }
  };

  return (
    <NavLink to={"e-learning"} className={"brand-link-pi"}>
      <img
        src={logo().url}
        alt={logo().alt}
        className={logo().class}
        style={{ opacity: ".8" }}
      />
    </NavLink>
  );
};
export default AsideBrandLogo;
