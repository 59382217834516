import FormAddEditArticle from "../Form/FormAddEditArticle";
import Breadcrumb from "../../../Template/Breadcrumb";
import { useEffect, useState } from "react";
import { ElearningArticleType } from "../../../Types/ElearningArticleType";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PILoader, { LoaderType } from "../../Loader/Loader";

const ElearningAddEditArticle = () => {
  const [article, setArticle] = useState<ElearningArticleType | null>(null);
  const [title, setTitle] = useState<string>("");
  const [newValues, setNewValues] = useState<ElearningArticleType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();
  const getArticle = () =>
    axios
      .get("article_url/" + params?.article_url)
      .then((response) => {
        if (response.status === 200) {
          setArticle(response.data);
          setTitle(`Editer l'article: ${response.data?.name}`);
        }
      })
      .catch((err) => console.error(err));

  useEffect(() => {
    if (!article && params?.article_url) {
      getArticle();
    } else if (!article && !params?.article_url) {
      setTitle(`Ajouter un article`);
    }
  }, [article]);

  const handleGetNewValues = (values: ElearningArticleType) => {
    setNewValues(values);
  };

  const handleDelete = () => {
    setIsLoading(true);
    if (article?.id) {
      axios.delete("article/" + article.id).then((response) => {
        setIsLoading(false);
        navigate("/admin");
      });
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    if (newValues) {
      if (newValues?.id) {
        axios.put("article/" + newValues.id, newValues).then((response) => {
          setIsLoading(false);
          navigate("/e-learning/" + response.data.url);
        });
      } else {
        newValues.url = "";
        axios.post("article", newValues).then((response) => {
          setIsLoading(false);
          navigate("/e-learning/" + response.data.url);
        });
      }
    }
  };

  return (
    <div className="content-wrapper">
      {title ? (
        <>
          {isLoading && "chargement"}
          <Breadcrumb title={title} />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title"></h3>
                    </div>
                    <div className="card-body">
                      <FormAddEditArticle
                        article={article}
                        setArticle={handleGetNewValues}
                      />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-6 justify-content-start d-flex">
                          {article && (
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDelete();
                              }}
                            >
                              Supprimer
                            </button>
                          )}
                        </div>
                        <div className="col-6 justify-content-end float-right d-flex">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSave();
                            }}
                          >
                            Enregister
                          </button>
                        </div>
                      </div>
                      <small className="text-center"></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mx-3 p-3">
          <PILoader type={LoaderType.Classic} />
        </div>
      )}
    </div>
  );
};

export default ElearningAddEditArticle;
