import Breadcrumb from "../../../Template/Breadcrumb";
import AddUserModal from "./AddUserModal";
import UserList from "./userList";
import { roleEnum } from "../../../Router/PrivateRoute";
import AdminGuard from "../AdminGuard";

const AdminUsersComponent = ({
  role = roleEnum.ROLE_USER,
}: {
  role?: roleEnum;
}) => {
  return (
    <AdminGuard role={role}>
      <div className="row">
        <div className="col">
          <Breadcrumb title="Utilisateurs" />
        </div>
        <div className="col content-header justify-content-end d-flex align-items-start mx-2">
          <AddUserModal />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <UserList />
        </div>
      </div>
    </AdminGuard>
  );
};

export default AdminUsersComponent;
