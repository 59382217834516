// import CoCart from "@cocart/cocart-rest-api";
import axios from "axios";

const CocartApi = () => {
  const WooCommerceProps: {
    PublicKey: string;
    PrivateKey: string;
    BaseUrl: string;
  } = {
    PublicKey: process.env.REACT_APP_WC_PUBLIC_KEY || "null",
    PrivateKey: process.env.REACT_APP_WC_PRIVATE_KEY || "null",
    BaseUrl: process.env.REACT_APP_WC_URL || "null",
  };

  const newInstance = () =>
    // new CoCart({
    //   url: WooCommerceProps.BaseUrl,
    //   consumerKey: WooCommerceProps.PublicKey,
    //   consumerSecret: WooCommerceProps.PrivateKey,
    //   version: "cocart/v2",
    // });
    axios.create({
      baseURL: WooCommerceProps.BaseUrl + "/wp-json/cocart/v2/",
      // transformResponse: (data) => JSON.parse(data),
      // transformRequest: (data) => JSON.stringify(data),
      // transformRequest: (data) => console.debug(data),
    });

  return newInstance();
};

export default CocartApi;
