import { NavLink } from "react-router-dom";
import { roleEnum } from "../../../Router/PrivateRoute";
import AdminGuard from "../AdminGuard";
import Breadcrumb from "../../../Template/Breadcrumb";
import { NewsArticleType } from "../../../Types/NewsArticleType";
import { useEffect, useState } from "react";
import axios from "axios";

const AdminNewsComponent = ({
  role = roleEnum.ROLE_USER,
}: {
  role?: roleEnum;
}) => {
  const [news, setNews] = useState<Array<NewsArticleType> | null>();
  const getNews = () =>
    axios
      .get("news")
      .then((response) => {
        if (response.status === 200) {
          setNews(response.data);
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  useEffect(() => {
    if (!news) {
      getNews();
    }
  }, [news]);

  return (
    <AdminGuard role={role}>
      <div className="row">
        <div className="col">
          <Breadcrumb title={"News"} />
        </div>
        <div className="col content-header justify-content-end d-flex align-items-start mx-2">
          <NavLink to={"/admin/news/article/add"} className={"btn btn-primary"}>
            <i className="fa fa-plus"></i> Ajouter une news
          </NavLink>
        </div>
      </div>
      <div className="row">
        {news &&
          news.map((news, key) => {
            return (
              <div className="col-3" key={key}>
                <div className="small-box bg-primary">
                  <div className="inner">
                    <h4>{news.title}</h4>
                    {/*<p>New Orders</p>*/}
                  </div>
                  <div className="icon">
                    <i className="fas fa-file"></i>
                  </div>
                  <NavLink
                    to={`news/article/edit/${news.id}`}
                    className="small-box-footer"
                  >
                    Editer <i className="fas fa-arrow-circle-right"></i>
                  </NavLink>
                </div>
              </div>
            );
          })}
      </div>
    </AdminGuard>
  );
};

export default AdminNewsComponent;
