import Header from "../Template/Header";
import Aside from "../Template/Aside";
import Footer from "../Template/Footer";
import setAxiosParams from "../Helpers/setAxiosParams";
import useAuth from "../Hooks/useAuth";
import useAuthToken from "../Hooks/useAuthToken";
import moment from "moment";
import "moment/locale/fr";
import React from "react";

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  setAxiosParams();
  useAuthToken();
  moment.locale("fr");
  const { isLoggedIn } = useAuth();
  return (
    <>
      {isLoggedIn() ? (
        <div className="wrapper">
          <Header />
          <Aside />
          {children}
          <Footer />
        </div>
      ) : (
        <div className="wrapper">{children}</div>
      )}
    </>
  );
};

export default Wrapper;
