import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BrandType } from "../../../Types/BrandType";

const AddUserModal = () => {
  const [sites, setSites] = useState<Array<any>>();
  const [brands, setBrands] = useState<BrandType>();
  const [roles, setRoles] = useState<Array<any>>();
  const defaultFormValues = {
    roles: ["ROLE_USER"],
  };
  const [newUser, setNewUser] = useState<any | undefined>(defaultFormValues);

  const handleCreateUser = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (newUser.username && newUser.email && newUser.roles && newUser.site) {
      axios
        .post("/user/add", newUser)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("Utilisateur ajouté", "", "success");
          }
        })
        .catch((e) => Swal.fire("Erreur", e.value, "error"));
    }
  };
  const getBrands = () =>
    axios.get("/brands").then((response) => {
      if (response.status === 200) {
        setBrands(response.data);
      }
    });
  const getRoles = () =>
    axios.get("/user/roles").then((response) => {
      if (response.status === 200) {
        setRoles(response.data);
      }
    });
  const getSites = () =>
    axios.get("/sites").then((response) => {
      if (response.status === 200) {
        setSites(response.data);
      }
    });
  useEffect(() => {
    if (!sites) {
      getSites();
    }
    if (!brands) {
      getBrands();
    }
    if (!roles) {
      getRoles();
    }
  }, []);
  return (
    <>
      <div
        className="modal fade"
        id="addUserModal"
        tabIndex={-1}
        aria-labelledby="addUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addUserModalLabel">
                Ajout d'utilisateur
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {sites && roles && (
              <form action="" id="addUserForm">
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                              type="text"
                              required={true}
                              id="username"
                              className="form-control"
                              onChange={(event) =>
                                setNewUser({
                                  ...newUser,
                                  username: event.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="username">Email</label>
                            <input
                              type="email"
                              required={true}
                              id="username"
                              className="form-control"
                              onChange={(event) =>
                                setNewUser({
                                  ...newUser,
                                  email: event.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="username">Rôle</label>
                            <select
                              id="role"
                              className="form-control"
                              onChange={(event) =>
                                setNewUser({
                                  ...newUser,
                                  roles: [event.target.value],
                                })
                              }
                            >
                              {Object.entries(roles).map(([value], key) => {
                                return (
                                  <option value={value} key={key}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="username">Site</label>
                            <select
                              id="username"
                              className="form-control"
                              defaultValue={"null"}
                              onChange={(event) =>
                                setNewUser({
                                  ...newUser,
                                  site: event.target.value,
                                })
                              }
                            >
                              <option value="null" disabled>
                                Choisir
                              </option>
                              {sites.map((site, key) => {
                                return (
                                  <option key={key} value={site.id}>
                                    {site.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        id={"closeBtn"}
                      >
                        Fermer
                      </button>
                    </div>
                    <div className="col-6 float-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => handleCreateUser(e)}
                        data-dismiss="modal"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#addUserModal"
      >
        <i className={"fa fa-plus mr-2"}></i>
        Ajouter un utilisateur
      </button>
    </>
  );
};

export default AddUserModal;
