import { Product } from "../Type/Product";
import { NavLink } from "react-router-dom";
import getPrice from "../Helpers/ShopHelper/getPrice";
// import ShowMoreText from "react-show-more-text";
const ProductCard = ({ product }: { product: Product }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="card" style={{ minHeight: "552px" }}>
        {product.images[0].src && (
          <img
            src={product.images[0].src["woocommerce_single"]}
            alt="category image"
            className="img-fluid"
            style={{
              objectFit: "cover",
              aspectRatio: "50/50",
            }}
          />
        )}
        <div className="card-body text-center">
          <h4 className="font-vitesse-600 text-center mb-4 product-list-title">
            {product.name}
          </h4>
          {/*{product?.description && (*/}
          {/*  <ShowMoreText more="Afficher plus" less="Afficher moins" lines={2}>*/}
          {/*    {product.description}*/}
          {/*  </ShowMoreText>*/}
          {/*)}*/}
          <span className="" style={{ fontSize: "1.2em" }}>
            {getPrice({
              productPrice: product.prices.price,
              suffix: product.prices.currency.currency_suffix,
            })}
            <small> HT</small>
          </span>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-around">
            <NavLink
              className="btn-primary btn"
              to={"/shop/product/" + product.id}
            >
              Voir le produit
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
