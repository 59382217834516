import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocalStorage } from "./useLocalStorage";
import { User } from "../Types/UserType";

interface UserContextType {
  user: User | null;
  isGettingUser: boolean;
  mutate: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { getItem, setItem } = useLocalStorage();
  const [user, setUser] = useState<User | null>(null);
  const [isGettingUser, setIsGettingUser] = useState<boolean>(false);

  const getUser = async () => {
    setIsGettingUser(true);
    try {
      const response = await axios.get("/user/me");
      if (response.status === 200) {
        setUser(response.data);
        setItem("user", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("ERROR", error);
    } finally {
      setIsGettingUser(false);
    }
  };

  const mutate = () => {
    const token: any = getItem("token");
    const userTmp: any = getItem("user");
    if (token && !userTmp) {
      getUser();
    } else {
      if (userTmp) {
        setUser(JSON.parse(userTmp));
      }
    }
  };

  useEffect(() => {
    mutate();
  }, []);

  return (
    <UserContext.Provider value={{ user, isGettingUser, mutate }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
