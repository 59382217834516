import { Navigate, Route, Routes } from "react-router-dom";
import Contact from "../Features/Contact/Page/Contact";
import PrivateRoute, { roleEnum } from "./PrivateRoute";
import ElearningSingle from "../Features/Home/Page/ElearningSingle";
import AdminHome from "../Features/Admin/Page/AdminHome";
import ElearningAddEditArticle from "../Features/Admin/Page/ElearningAddEditArticle";
import ELearning from "../Features/Home/Page/ELearning";
import Login from "../Features/Authentication/Page/Login";
import ShopHome from "../Features/Shop/Page/ShopHome";
import ShopCategory from "../Features/Shop/Page/ShopCategory";
import ShopBase from "../Features/Shop/Page/ShopBase";
import ShopProductSingle from "../Features/Shop/Page/ShopProductSingle";
import ShopCart from "../Features/Shop/Page/ShopCart";
import News from "../Features/News/Page/News";
import NewsSingle from "../Features/News/Page/NewsSingle";
import NewsAddEditArticle from "../Features/Admin/Page/NewsAddEditArticle";

const RouterPath = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/e-learning"} replace />} />
      <Route
        path="/news"
        element={
          <PrivateRoute role={roleEnum.ROLE_USER}>
            <News />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/news/:news_id"
        element={
          <PrivateRoute role={roleEnum.ROLE_USER}>
            <NewsSingle />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/admin/news/article/edit/:news_id"
        element={<NewsAddEditArticle />}
      />
      <Route path="/admin/news/article/add/" element={<NewsAddEditArticle />} />
      <Route
        path="/e-learning"
        element={
          <PrivateRoute role={roleEnum.ROLE_USER}>
            <ELearning />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/e-learning/:article_url"
        element={
          <PrivateRoute role={roleEnum.ROLE_USER}>
            <ElearningSingle />
          </PrivateRoute>
        }
      />
      <Route
        path="/contact"
        element={
          <PrivateRoute role={roleEnum.ROLE_USER}>
            <Contact />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<Login />} />

      <Route
        path="/admin"
        element={
          <PrivateRoute role={roleEnum.ROLE_REDACTOR}>
            <AdminHome />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/elearning/article/edit/:article_url"
        element={<ElearningAddEditArticle />}
      />
      <Route
        path="/admin/elearning/article/add"
        element={<ElearningAddEditArticle />}
      />
      <Route
        path="/cart"
        element={
          <PrivateRoute>
            <ShopCart />
          </PrivateRoute>
        }
      />
      <Route
        path="/shop"
        element={
          <PrivateRoute role={roleEnum.ROLE_USER}>
            <ShopBase />
          </PrivateRoute>
        }
      >
        <Route
          index={true}
          element={
            <PrivateRoute role={roleEnum.ROLE_USER}>
              <ShopHome />
            </PrivateRoute>
          }
        />
        <Route
          path="category/:category"
          element={
            <PrivateRoute role={roleEnum.ROLE_USER}>
              <ShopCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="product/:product_id"
          element={
            <PrivateRoute role={roleEnum.ROLE_USER}>
              <ShopProductSingle />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default RouterPath;
