import { NavLink } from "react-router-dom";
import useCategories from "../../../Hooks/WooCommerce/useCategories";
import PILoader, { LoaderType } from "../../Loader/Loader";
import ProductImageNotFound from "../Components/ProductImageNotFound";

const ShopHome = () => {
  const { categories } = useCategories();

  return (
    <>
      {!categories && (
        <div className="p-5">
          <PILoader type={LoaderType.Logo_Black} size="200px" />
        </div>
      )}
      {categories && (
        <div className="row">
          {categories &&
            categories
              .filter(
                (category) =>
                  category.parent === 0 && category.slug !== "uncategorized",
              )
              .map((category: any, index: any) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="font-vitesse-800 text-center">
                          {category.name}
                        </h3>
                      </div>
                      {category?.image?.src ? (
                        <img
                          src={category.image.src}
                          alt="category image"
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            aspectRatio: "50/50",
                          }}
                        />
                      ) : (
                        <ProductImageNotFound />
                      )}
                      <div className="card-footer">
                        <NavLink to={"category/" + category.slug}>
                          Voir les produits
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </>
  );
};

export default ShopHome;
