import { useEffect, useState } from "react";
import { ElearningArticleType } from "../../../Types/ElearningArticleType";
import axios from "axios";
import PILoader, { LoaderType } from "../../Loader/Loader";
import AdminElearningComponent from "../Components/AdminElearningComponent";
import AdminUsersComponent from "../Components/AdminUsersComponent";
import AdminSitesComponent from "../Components/AdminSitesComponent";
import { roleEnum } from "../../../Router/PrivateRoute";
import AdminNewsComponent from "../Components/AdminNewsComponent";

const AdminHome = () => {
  const [articlesElearning, setArticlesElearning] =
    useState<Array<ElearningArticleType> | null>(null);
  const getArticlesElearning = () =>
    axios
      .get("/articles")
      .then((response) => {
        if (response.status === 200) {
          setArticlesElearning(response.data);
        }
      })
      .catch((error) => console.error(error));
  useEffect(() => {
    if (!articlesElearning) {
      getArticlesElearning();
    }
  }, [articlesElearning]);

  return (
    <div className="content-wrapper">
      {!articlesElearning && (
        <PILoader type={LoaderType.Classic} className={"mx-3 p-2"} />
      )}
      <div className="content pi-contact">
        <div className="container-fluid">
          <AdminElearningComponent
            articlesElearning={articlesElearning}
            role={roleEnum.ROLE_ADMIN}
          />
          <AdminUsersComponent role={roleEnum.ROLE_ADMIN} />
          <AdminSitesComponent role={roleEnum.ROLE_ADMIN} />
          <AdminNewsComponent role={roleEnum.ROLE_REDACTOR} />
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
