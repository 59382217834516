import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Wrapper from "./Wrapper/wrapper";
import RouterPath from "./Router/RouterPath";
import "admin-lte/plugins/fontawesome-free/css/all.css";
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte.min";
import "admin-lte/plugins/bootstrap/js/bootstrap.min";
import "./Assets/Styles/style.scss";
import "admin-lte/plugins/fontawesome-free/css/all.css";
import { UserProvider } from "./Hooks/useUser";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <Wrapper>
          <RouterPath />
        </Wrapper>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
