import { JSX } from "react";

export enum LoaderType {
  Classic,
  Logo_Black,
  Logo_White,
}

const PILoader = ({
  type,
  size,
  className,
}: {
  type?: LoaderType;
  size?: string;
  className?: string;
}) => {
  const sizeValue = size || "100%";
  const LoaderSwitch = (): JSX.Element => {
    switch (type) {
      case LoaderType.Classic:
        return (
          <div
            className={
              "container d-flex flex-column justify-content-center align-items-center"
            }
          >
            <i
              className={`fas fa-sync fa-spin ${className}`}
              style={{ maxWidth: sizeValue }}
            ></i>
          </div>
        );
      case LoaderType.Logo_Black:
        return (
          <img
            className={`fa-bounce ${className}`}
            style={{ maxWidth: sizeValue }}
            src="/img/logo/pi_logo_black.png"
            alt="loader"
          />
        );
      case LoaderType.Logo_White:
        return (
          <img
            className={`fa-bounce ${className}`}
            style={{ maxWidth: sizeValue }}
            src="/img/logo/pi_logo_white.png"
            alt="loader"
          />
        );
      default:
        return (
          <i
            className={`fas fa-sync fa-spin ${className}`}
            style={{ maxWidth: sizeValue }}
          ></i>
        );
    }
  };
  return <div className="loader-container">{LoaderSwitch()}</div>;
};

export default PILoader;
