import { NavLink } from "react-router-dom";
import Breadcrumb from "../../../Template/Breadcrumb";
import { useRef, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function Contact() {
  const debug: boolean = false;
  const [formValues, setFormValues] = useState<any | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [isSend, setIsSend] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const deviceOptions = [
    { value: "Machine à fumée" },
    { value: "Éclairage" },
    { value: "Interface de contrôle" },
    { value: "Son" },
    { value: "Autre" },
  ];
  const handleSend = (e: any, data: any) => {
    if (!debug) {
      axios
        .post("contact", formValues)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire("Demande envoyée", "", "success");
            formRef.current && formRef.current.reset();
            setFormValues(null);
            setIsSend(true);
          }
        })
        .catch((errors) => {
          Swal.fire(
            "Une erreur est survenue merci de réésayer dans quelques instants",
            "",
            "error",
          );
        });
    }
    if (debug) {
      Swal.fire("Demande envoyée", "", "success");
      formRef.current && formRef.current.reset();
      setFormValues(null);
    }
  };

  return (
    <div className="content-wrapper">
      <Breadcrumb title={"Demande d'intervention"} />
      <div className="content pi-contact">
        <div className="container-fluid">
          {!isSend ? (
            <div className="row">
              <div className="col-lg-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title"></h3>
                  </div>
                  <form onSubmit={handleSubmit(handleSend)} ref={formRef}>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Appareil</label>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            setFormValues({
                              ...formValues,
                              device: event.target.value,
                            });
                          }}
                        >
                          <option defaultChecked value="null">
                            Choisissez...
                          </option>
                          {deviceOptions.map((device, key) => {
                            return (
                              <option key={key} value={device.value}>
                                {device.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Référence appareil</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              "device-ref": e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Sujet</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="sujet"
                          {...register("sujet", { required: true })}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              subject: e.target.value,
                            })
                          }
                        />
                        {errors.sujet && (
                          <span className="text-red">Ce champs est requis</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          className="form-control"
                          placeholder=""
                          {...register("message", { required: true })}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                        {errors.message && (
                          <span className="text-red">Ce champs est requis</span>
                        )}
                      </div>
                    </div>

                    <div className="card-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="accept"
                              {...register("consent", { required: true })}
                            />
                            <label className="ml-2" htmlFor="accept">
                              J'assure avoir lu la documentation et la
                              <NavLink to={"/e-learning"}>&nbsp;FAQ</NavLink>
                            </label>
                            {errors.consent && (
                              <span className="text-red d-block">
                                Vous devez accepter d'avoir lu la documentation
                                et la FAQ
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-6 justify-content-end float-right d-flex">
                          <button type="submit" className="btn btn-primary">
                            Envoyer ma demande
                          </button>
                        </div>
                      </div>
                      <small className="text-center"></small>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <p>Message envoyé</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contact;
