import axios from "axios";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { BrandType } from "../../../Types/BrandType";
import { Site } from "../../../Types/SiteType";

interface SiteInterface {
  site?: {
    id?: string;
    name?: string;
    first_name?: string;
    last_name?: string;
    address?: string;
    cp?: string;
    city?: string;
    phone?: string;
    brand?: any;
  };
  brandId?: string;
}
const AddEditSiteModal = ({
  site = undefined,
  dataId = null,
}: {
  site?: Site;
  dataId?: null | number;
}) => {
  const defaultSiteState: SiteInterface = { brandId: "1" };
  const [siteState, setSiteState] = useState<SiteInterface>(defaultSiteState);
  const [brands, setBrands] = useState<Array<BrandType> | undefined>(undefined);
  const handleCreateSite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (siteState) {
      if (site) {
        axios
          .put("/site", siteState)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire("Site mis à jour", "", "success");
              setSiteState(defaultSiteState);
            }
          })
          .catch((e) => Swal.fire("Erreur", e.value, "error"));
      } else {
        axios
          .post("/site", siteState)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire("Site ajouté", "", "success");
              setSiteState(defaultSiteState);
            }
          })
          .catch((e) => Swal.fire("Erreur", e.value, "error"));
      }
    }
  };
  const getBrands = () =>
    axios.get("brands").then((response) => {
      if (response.status === 200) {
        setBrands(response.data);
      }
    });

  useEffect(() => {
    if (!brands) {
      getBrands();
    }
    if (site) {
      setSiteState({ site });
    }
  }, []);

  return (
    <>
      <div
        className="modal fade"
        id={`addEditSiteModal${dataId ?? ""}`}
        tabIndex={-1}
        aria-labelledby="addEditSiteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addSiteLabel">
                {site ? `Editer le site ${site.name}` : "Ajout d'un site"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form action="" id="addSiteForm">
              <input type="hidden" value={site?.id && site.id} />
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-12">
                        <h4>Site</h4>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">Nom</label>
                          <input
                            type="text"
                            required={true}
                            id="siteName"
                            className="form-control"
                            defaultValue={siteState.site?.name}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  name: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        {brands && (
                          <div className="form-group">
                            <label htmlFor="site">Brand</label>
                            <select
                              required={true}
                              id="siteBrand"
                              className="form-control"
                              defaultValue={siteState.site?.brand?.id}
                              onChange={(event) =>
                                setSiteState({
                                  ...siteState,
                                  brandId: event.target.value,
                                })
                              }
                            >
                              {brands.map((brand, key) => {
                                return (
                                  <option key={key} value={brand.id}>
                                    {brand.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">Adresse</label>
                          <input
                            type="text"
                            required={true}
                            id="siteAdress"
                            className="form-control"
                            defaultValue={siteState.site?.address}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  address: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">Code postal</label>
                          <input
                            type="text"
                            required={true}
                            id="siteCp"
                            className="form-control"
                            defaultValue={siteState.site?.cp}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  cp: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">Ville</label>
                          <input
                            type="text"
                            required={true}
                            id="siteCity"
                            className="form-control"
                            defaultValue={siteState.site?.city}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  city: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <hr />
                        <h4>Responsable</h4>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">Nom responsable</label>
                          <input
                            type="text"
                            required={true}
                            id="managers-lastname"
                            className="form-control"
                            defaultValue={siteState.site?.last_name}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  last_name: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">Prénom responsable</label>
                          <input
                            type="text"
                            required={true}
                            id="managers-firstname"
                            className="form-control"
                            defaultValue={siteState.site?.first_name}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  first_name: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="site">N° Téléphone</label>
                          <input
                            type="text"
                            required={true}
                            id="managers-phone"
                            className="form-control"
                            defaultValue={siteState.site?.phone}
                            onChange={(event) =>
                              setSiteState({
                                ...siteState,
                                site: {
                                  ...siteState.site,
                                  phone: event.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      id={"closeBtn"}
                    >
                      Fermer
                    </button>
                  </div>
                  <div className="col-6 float-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => handleCreateSite(e)}
                      data-dismiss="modal"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {site ? (
        <button
          className="btn"
          data-target={`#addEditSiteModal${dataId}`}
          data-toggle="modal"
        >
          <i className="fa fa-edit"></i>
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#addEditSiteModal"
        >
          <i className={"fa fa-plus mr-2"}></i>
          Ajouter un site
        </button>
      )}
    </>
  );
};

export default AddEditSiteModal;
