import { roleEnum } from "../../Router/PrivateRoute";
import { useUser } from "../../Hooks/useUser";

type AdminGuardProps = {
  children: React.ReactNode;
  role: roleEnum;
};

const AdminGuard: React.FC<AdminGuardProps> = ({ children, role }) => {
  const { user } = useUser();
  return <>{user && user.roles.includes(roleEnum[role]) && children}</>;
};

export default AdminGuard;
