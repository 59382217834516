import useAuth from "../../../Hooks/useAuth";
import { useEffect, useState } from "react";
import { User } from "../../../Types/UserType";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../../../Template/Breadcrumb";
import PILoader, { LoaderType } from "../../Loader/Loader";
import { NewsArticleType } from "../../../Types/NewsArticleType";

function News() {
  const [user, setUser] = useState<User | null>(null);
  const [news, setNews] = useState<Array<NewsArticleType> | null>(null);
  const { getUser } = useAuth();

  const getArticles = () => {
    axios
      .get("news")
      .then((response) => {
        console.debug(response);
        if (response.status === 200) {
          setNews(response.data);
        }
        if (response.status === 404) {
          console.error("Article not found");
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  useEffect(() => {
    if (!news) {
      getArticles();
    }
  }, [news]);

  useEffect(() => {
    if (!user) {
      setUser(getUser());
    }
  }, [user]);

  return (
    <div className="content-wrapper">
      <Breadcrumb title={`News`}>
        {user?.roles.includes("ROLE_MANAGER") && (
          <div className="float-right">
            <NavLink to="/admin/news/article/add">
              <i className="fa fa-plus-circle color-primary"></i>
            </NavLink>
          </div>
        )}
      </Breadcrumb>
      {!news && <PILoader type={LoaderType.Classic} className={"mx-3 p-2"} />}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {news &&
                  news.map((news, key) => {
                    return (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6"
                        key={key}
                      >
                        <div
                          className="card-overlay"
                          style={{
                            background:
                              "url(" +
                              axios.getUri() +
                              news.image_url?.url +
                              ")  ",
                          }}
                        >
                          <div className="card elearning-card p-0">
                            <div className="card-body p-3">
                              <h2 className="card-title pt-2 pb-3">
                                {news.title}
                              </h2>
                            </div>
                            <div className="card-footer">
                              <NavLink
                                to={news?.id.toString() || "null"}
                                className={"card-link"}
                              >
                                Voir la news
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
