import useAuth from "../../../Hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Loader, { LoaderType } from "../../Loader/Loader";

const Login = () => {
  const { login, isLogin } = useAuth();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const handleLogin = (e: any) => {
    e.preventDefault();
    if (username && password) {
      login({
        username: username,
        password: password,
      });
    }
  };

  return (
    <>
      <div className="hold-transition login-page dark-mode">
        <div className="login-box">
          <div className="card card-outline card-primary">
            {isLogin ? (
              <Loader
                type={LoaderType.Logo_White}
                size={"230px"}
                className="m-auto p-3 m-2"
              />
            ) : (
              <>
                <div className="card-header text-center">
                  <NavLink to={"/"} className="login-logo">
                    <img
                      src="/img/logo/pi_logo_white.png"
                      className="img-fluid"
                      alt=""
                    />
                  </NavLink>
                </div>
                <div className="card-body">
                  <p className="login-box-msg">
                    Connectez-vous à l'espace E-Learning
                  </p>
                  <form method="post">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Utilisateur"
                        autoComplete="username"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-user"></span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        className="form-control"
                        autoComplete="current-password"
                        placeholder="Password"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-6">
                        <button
                          type="submit"
                          onClick={(e) => handleLogin(e)}
                          className="btn btn-primary btn-block"
                        >
                          Connexion
                        </button>
                      </div>
                    </div>
                  </form>

                  <p className="mb-1 mt-3 text-center">
                    <a href="forgot-password.html">
                      J'ai oublié mon mot de passe
                    </a>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
