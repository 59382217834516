import axios from "axios";

export default function setAuthToken(token?: string) {
  const token_local_storage = localStorage.getItem("token");
  const newToken = token || token_local_storage;

  if (newToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  // axios.interceptors.response.use(null, function (error) {
  //   return Promise.reject(error);
  // });
}
