import { useEffect, useState } from "react";
import CocartApi from "../../../Hooks/WooCommerce/CocartApi";
import ProductCard from "../Components/ProductsCard";
import { ProductsObject } from "../Type/Product";
import PILoader, { LoaderType } from "../../Loader/Loader";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import { useSearchParams } from "react-router-dom";
import { useUser } from "../../../Hooks/useUser";

const ShopProductsList = ({ categoryId }: { categoryId?: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState<ProductsObject | undefined | null>(
    undefined,
  );
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [current, setCurrent] = useState(1);
  const [isGettingProduct, setIsGettingProduct] = useState<boolean>(false);

  const { user } = useUser();

  const getProducts = () => {
    setIsloading(true);
    let slug = "products";
    if (categoryId) {
      slug += "?category=" + categoryId;
    }
    if (user?.site?.brand.shopTag) {
      slug += "&tag=" + user.site.brand.shopTag;
    }
    if (searchParams.get("page")) {
      slug += "&page=" + searchParams.get("page");
    }
    CocartApi()
      .get(slug)
      .then((response) => {
        setProducts(response.data);
        setIsloading(false);
        setIsGettingProduct(false);
      });
  };

  const handlePage = (page: number) => {
    setCurrent(page);
    setSearchParams({ page: page.toString() });
  };

  useEffect(() => {
    if (!products && !isGettingProduct) {
      setIsGettingProduct(true);
      getProducts();
    }
  }, [products]);

  useEffect(() => {
    getProducts();
  }, [categoryId]);

  useEffect(() => {
    setProducts(null);
    const params = searchParams.get("page");
    params && setCurrent(parseInt(params));
  }, [searchParams]);

  return (
    <>
      <div className="row">
        {isLoading && (
          <div className="p-5 m-auto">
            <PILoader type={LoaderType.Logo_Black} size={"200px"} />
          </div>
        )}
        {products &&
          !isLoading &&
          products.products.map((product, index) => {
            return <ProductCard product={product} key={index} />;
          })}
      </div>
      <div className="row text-center m-auto">
        <div className="col p-5">
          {products && products.total_pages > 1 && (
            <Pagination
              total={products.total_products}
              defaultPageSize={10}
              onChange={handlePage}
              current={current}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ShopProductsList;
