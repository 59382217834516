import currency from "currency.js";

const getPrice = ({
  productPrice,
  suffix,
  fromCents = true,
}: {
  productPrice: number;
  suffix: string;
  fromCents?: boolean;
}) => {
  return currency(productPrice, {
    fromCents: fromCents,
    symbol: suffix,
    pattern: "# !",
  }).format();
};

export default getPrice;
