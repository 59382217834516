import Breadcrumb from "../../../Template/Breadcrumb";
import AddEditSiteModal from "./AddEditSiteModal";
import SiteList from "./SiteList";
import { roleEnum } from "../../../Router/PrivateRoute";
import AdminGuard from "../AdminGuard";

const AdminSitesComponent = ({
  role = roleEnum.ROLE_USER,
}: {
  role?: roleEnum;
}) => {
  return (
    <AdminGuard role={role}>
      <div className="row">
        <div className="col">
          <Breadcrumb title="Sites" />
        </div>
        <div className="col content-header justify-content-end d-flex align-items-start mx-2">
          <AddEditSiteModal />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SiteList />
        </div>
      </div>
    </AdminGuard>
  );
};
export default AdminSitesComponent;
